import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import AwardsCeremonyVideo from "../components/awards-ceremony-video"

const ShortlistPage = () => (
  <Background>
    <Layout>
      <SEO title="Watch" />
      <Container>
        <div style={{margin: `6rem 0`}}>
          <AwardsCeremonyVideo title="Awards Ceremony 2020" />
        </div>
      </Container>
    </Layout>
  </Background>
)

export default ShortlistPage
